import { useHistory } from 'react-router-dom';

import Add from '../components/common/Add';
import TransactionList from '../components/layout/transactions/TransactionList';

const Transactions = (props) => {
  const history = useHistory();

  if (!localStorage.getItem('user_token')) {
    history.push('/login');
  }

  function AddTransaction() {
    history.push('/addTransaction');
  }

  return (
    <div>
      <div className="flex px-8 items-center">
        <h1 className="text-4xl">Transactions</h1>
        <Add addFunction={AddTransaction} title="Add Transaction" />
      </div>
      <TransactionList />
      <br />
    </div>
  );
};

export default Transactions;
