import { Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Transactions from "./pages/Transactions";
import AddTransaction from "./pages/AddTransaction";
import Searching from "./pages/Searching";
import Calculator from "./pages/Calculator";
import Wallets from "./pages/Wallets";
import AddWallet from "./pages/AddWallet";
import Fiat from "./pages/Fiat";
import AddFiat from "./pages/AddFiat";
import Favourites from "./pages/Favourites";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <div className="container dark:bg-gray-900 dark:text-white min-h-screen pt-5 min-w-full">
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/addTransaction">
          <AddTransaction />
        </Route>
        <Route path="/addWallet">
          <AddWallet />
        </Route>
        <Route path="/search">
          <Searching />
        </Route>
        <Route path="/calculator">
          <Calculator />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/transactions">
          <Transactions />
        </Route>
        <Route path="/wallets">
          <Wallets />
        </Route>
        <Route path="/fiats">
          <Fiat />
        </Route>
        <Route path="/addFiat">
          <AddFiat />
        </Route>
        <Route path="/favourites">
          <Favourites />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
