import { useHistory } from 'react-router-dom';

import Add from '../components/common/Add';
import FiatList from '../components/layout/fiat/FiatList';

const Fiat = () => {
  const history = useHistory();

  if (!localStorage.getItem('user_token')) {
    history.push('/login');
  }

  function redirectToAddFiatPage() {
    history.push('/addFiat');
  }

  return (
    <div>
      <div className="flex px-8 items-center">
        <h1 className="text-4xl">Fiats</h1>
        <Add title="Add Fiat" addFunction={redirectToAddFiatPage} />
      </div>
      <FiatList />
    </div>
  );
};

export default Fiat;
